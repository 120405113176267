<template>
  <v-skeleton-loader v-if="loading" type="card, card, article, date-picker"></v-skeleton-loader>
  <div v-else class="mx-auto">
    <div class="sticky-header d-flex justify-space-between mb-4" :class="sticky_menu ? 'fixed' : ''">
      <v-btn :ripple="false" tile text class="pa-0 font-13px" active-class="active" :to="getCustomerViewRoute('basic')">
        基本
      </v-btn>
      <v-btn :ripple="false" tile text class="pa-0 font-13px" active-class="active" :to="getCustomerViewRoute('visitmemo')">
        訪問 ({{ customer?.visit_memo_count }})
      </v-btn>
      <v-btn :ripple="false" tile text class="pa-0 font-13px" active-class="active" :to="getCustomerViewRoute('task')">
        ﾀｽｸ ({{ count?.tasks }})
      </v-btn>
      <v-btn :ripple="false" tile text class="pa-0 font-13px" active-class="active" :to="getCustomerViewRoute('photos')">
        写真 ({{ count?.photos || 0 }})
      </v-btn>
      <v-btn :ripple="false" tile text class="pa-0 font-13px" active-class="active" :to="getCustomerViewRoute('claim')" @click="updateSiteContent()">
        ｸﾚｰﾑ/ｱﾌﾀ ({{ count?.claimsAndAfterServices || 0 }})
      </v-btn>
    </div>

    <v-row no-gutters>
      <v-col v-if="!isBasicView && !hideView" cols="12">
        <SiteSelect :customer="customer" :allServices="allServices" :project-id.sync="project_id" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <router-view :customer="customer" :project_id.sync="project_id" :project="project">
        </router-view>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SiteSelect from "../../components/layout/customer/SiteSelect.vue";
export default {
  name: "Customer",
  // eslint-disable-next-line vue/no-unused-components
  components: { SiteSelect },
  props: ["customer_id", "site_id"],
  data() {
    return {
      items: ["基本情報", "タスク履歴", "写真", "クレーム"],
      sticky_menu: false,
      service_id: 0,
      site_key: 0,
      project_id: null,
      loading: false
    };
  },
  watch: {
    "site_id": function () {
      this.project_id = this.selectedSite.projects[0].id;
    }
  },
  computed: {
    ...mapGetters(["getServiceTypes"]),
    customer() {
      return this.$store.getters.getCustomer;
    },
    allServices() {
      return this.getServiceTypes;
    },
    selectedSite() {
      return this.customer?.sites?.find(site => site.id == this.site_id);
    },
    project() {
      return this.selectedSite?.projects.find(project => {
        return project.id == this.project_id;
      });
    },
    isBasicView() {
      return this.$route.name === "customer-basic";
    },
    hideView() {
      return this.$route.name === "customer-visitmemo";
    },
    sites() {
      return this.customer?.sites
    },
    count() {
      let tasks = this.sites?.reduce((sum, site) => {
        return sum + site.tasks_count
      }, 0)

      let photos = this.sites?.reduce((sum, site) => {
        return sum + site.photos_count
      }, 0)

      // Get the total amount of either claims or after services
      let claimsAndAfterServices = 0

      // Because either claims or after services are related with project, we must iterate through the projects instead
      // of directly from sites.
      this.sites?.forEach(site => {
        claimsAndAfterServices += site.projects.reduce((sum, project) => {
          let count =
            project.service_type_id === 0
              ? project.coating_afters_count
              : project.claims_count
          return sum + count
        }, 0)
      })
      return { tasks: tasks, photos: photos, claimsAndAfterServices }
    },
  },
  created() {
    this.updateCustomer();
    //await this.$store.dispatch("CUSTOMER_CLAIM_GET_BY", this.customer_id)
  },
  methods: {
    updateCustomer() {
      this.loading = true;
      let params = {
        customer_id: this.customer_id,
        total_coating_afters: 10
      }
      this.$store
        .dispatch("CUSTOMER_GET", params)
        .then(() => {
          this.updateSite();
          if (this.$store.getters.selectedProject) {
            this.project_id = this.$store.getters.selectedProject;
            this.$store.commit("resetSelectedProject");
          } else {
            this.project_id = this.selectedSite?.projects[0]?.id;
          }
        })
        .finally(() => (this.loading = false));
    },
    updateScroll() {
      if (window.scrollY > 115) {
        this.sticky_menu = true;
      } else {
        this.sticky_menu = false;
      }
    },
    async updateSite() {
      let site_id = this.site_id;
      await this.$store.dispatch("GET_SERVICE_TYPE", site_id);
    },
    updateSiteContent() {
      this.$root.$emit('updateCompnent')
    },
    getCustomerViewRoute(part) {
      return {
        name: `customer-${part}`,
        params: {
          customer_id: this.$route.params.customer_id,
          site_id: this.$route.params.site_id
        }
      };
    }
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  }
};
</script>

<style lang="scss" scoped>
.active {
  color: #4f55a7;
  border-bottom: 1px solid #4f55a7;
  font-weight: 700;
}

.active:before,
.v-btn:hover:before {
  opacity: 0;
}

.sticky-header {
  &.fixed {
    position: fixed;
    background-color: white;
    z-index: 3;
    top: 0;
    left: 0;
    padding: 10px;
    padding-right: 50px;
    width: 100%;
    box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.75);
  }
}
.font-13px{
  font-size: 13px;
}
</style>
