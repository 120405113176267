<template>
  <div class="customer-box">
    <v-row>
      <v-col cols="12">
        <div class="customer-name-top">
          {{ customer.furigana_name }}
        </div>
        <div class="customer-name-bottom">
          {{ customer.name }}
        </div>
      </v-col>
    </v-row>
    <v-row class="mb-5 justify-center">
      <v-col cols="12">
        <v-select v-model="siteId" :items="sites" class="customer-box-form-input" hide-details
          placeholder="顧客名 / 物件名を入力してください" outlined dense color="#4F55A7" item-color="#4F55A7" background-color="white"
          @input="updateTableData(siteId, 'site')">
          <template v-slot:item="{ item }">
            {{ item.text }}
            <template v-if="item.count > 0">
              ({{ item.count }})
            </template>
          </template>
          <template v-slot:selection="{ item }">
            {{ item.text }}
            <template v-if="item.count > 0">
              ({{ item.count }})
            </template>
          </template>
        </v-select>
      </v-col>
      <v-icon color="rgba(79, 85, 167, 0.5)">mdi-menu-down</v-icon>
      <v-col cols="12">
        <v-select v-model="_projectId" :items="services" class="customer-box-form-input" hide-details
          placeholder="Related service type" outlined dense color="#4F55A7" item-color="#4F55A7"
          background-color="white">
          <!-- @input="updateTableData(_projectId, 'Proj')" -->
          <template v-slot:item="{ item }">
            {{ item.text }}
            <template v-if="item.count > 0">
              ({{ item.count }})
            </template>
          </template>
          <template v-slot:selection="{ item }">
            {{ item.text }}
            <template v-if="item.count > 0">
              ({{ item.count }})
            </template>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="3" class="customer-box-detail-headline mb-3">物件名</v-col>
      <v-col cols="9" class="customer-box-detail-content font-weight-bold">
        {{ selectedSite.name }}
      </v-col>
      <v-col v-if="selectedSite.furigana_name" cols="3" class="customer-box-detail-headline mb-3">物件名（かな）</v-col>
      <v-col v-if="selectedSite.furigana_name" cols="9" class="customer-box-detail-content font-weight-bold">
        {{ selectedSite.furigana_name }}
      </v-col>
      <v-col cols="3" class="customer-box-detail-headline mb-3">サービス
      </v-col>
      <v-col cols="9" class="customer-box-detail-content">
        {{ serviceType.name || "" }}
      </v-col>
      <v-col cols="3" class="customer-box-detail-headline mb-2">所在地</v-col>
      <v-col cols="9" class="customer-box-detail-content">
        {{ customerAddress }}
      </v-col>
      <v-col v-if="selectedSite.file_pdf" cols="3" class="customer-box-detail-headline mb-2">PDF</v-col>
      <v-col v-if="selectedSite.file_pdf" cols="9" class="customer-box-detail-content">
        <a :href="selectedSite.file_pdf_url" target="_blank" class="mr-2 blue--text text-decoration-underline">{{
            selectedSite.file_pdf_name
        }}</a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SiteSelect",
  props: {
    customer: {
      type: Object,
      default: null,
      required: true
    },
    allServices: {
      type: Array,
      default: null,
      required: true
    },
    projectId: {
      type: [Number, String],
      default: null,
      required: false
    }
  },
  computed: {
    _projectId: {
      get() {
        return this.projectId;
      },
      set(newValue) {
        this.updateTableData(newValue, 'Proj')
        return this.$emit("update:project-id", newValue);
      }
    },
    siteId: {
      get() {
        return parseInt(this.$route.params.site_id);
      },
      set(val) {
        this.$router.replace({
          name: this.$route.name,
          params: {
            customer_id: this.customer.id,
            site_id: val
          }
        });
      }
    },
    sites() {
      // return this.customer.sites;
      return this.customer.sites.map(site => {
        let count = 0
        if (this.isClaimOrAfterServicePage) {
          count = site.projects.reduce((sum, site) => {
            return sum + site?.claims_count + site?.coating_afters_count
          }, 0)
        } else if (this.$route.name === 'customer-task') {
          count = site?.tasks_count
        } else if (this.$route.name === 'customer-photos') {
          count = site?.photos_count
        }
        return { value: site.id, text: site.name, count: count, projects: site.projects }
      })
    },
    selectedSite() {
      return this.customer.sites.find(site => site.id === this.siteId);
    },
    projects() {
      return this.selectedSite?.projects;
    },
    selectedProject() {
      return this.projects?.find(project => project.id == this._projectId);
    },
    services() {
      // return this.projects.map(project => {
      //   return {
      //     value: project.id,
      //     text: project.service_type.name,
      //     count:
      //       project.service_type_id === 0
      //         ? project?.coating_afters_count
      //         : project?.claims_count
      //   };
      // });

      return this.projects.map(project => {
        let count = 0
        if (this.isClaimOrAfterServicePage) {
          count =
            project.service_type_id === 0
              ? project?.coating_afters_count
              : project?.claims_count
        } else if (this.$route.name === 'customer-task') {
          count = project?.tasks_count
        } else if (this.$route.name === 'customer-photos') {
          count = this.selectedSite?.photos_count
        }
        return {
          value: project.id,
          text: project.service_type.name,
          count: count
        }
      })
    },
    isClaimOrAfterServicePage() {
      return (
        this.$route.name === "customer-claim" ||
        this.$route.name === "customer-after-service"
      );
    },
    serviceType() {
      return this.selectedProject?.service_type;
    },
    customerAddress() {
      if (
        !this.selectedSite?.prefecture?.name &&
        !this.selectedSite?.city &&
        !this.selectedSite?.address
      )
        return "-";
      return `
      ${this.selectedSite?.prefecture?.name || ""}
      ${this.selectedSite?.city || ""}
      ${this.selectedSite?.address || ""}`;
    },
    APIAction() {
      if (this.$route.name === "customer-task") {
        return "CUSTOMER_TASKS_FILTER";
      } else if (this.$route.name === "customer-photos") {
        return "PHOTO_GET_ALL_BY_CUSTOMER_ID";
      } else if (this.$route.name === "customer-claim") {
        return "CUSTOMER_CLAIM_GET_BY";
      } else if (this.$route.name === "customer-after-service") {
        return "CUSTOMER_GET_ALL_AFTER_SERVICE";
      }
      return "";
    }
  },
  mounted() {
    this.updateTableData(this.projectId);
  },
  methods: {
    openFilePDF(url) {
      window.open(url, "_blank");
    },
    limitStr(string, limit) {
      let str = string;

      if (typeof str === "string" && str.length > limit) {
        str = str.slice(0, limit) + "...";
      }

      return str;
    },
    updateTableData(project_id, type = null) {
      let project = {}
      if (type == 'Proj' || !type) {
        project = this.projects.find(project => project.id == project_id);
      } else if (type == 'site') {
        project = this.sites.find(site => site.value == project_id).projects[0];
      }

      let param = {
        id: this.customer.id,
        customer_id: this.customer.id,
        site_id: this.siteId,
        project_id: project_id,
        paginate: 10
      };
      if (project?.service_type.id === 0) {
        param.coating_id = project?.svc_coating.id;
        if (this.$route.name === "customer-claim" || project?.service_type.id === 0) {
          this.$router.push({ name: "customer-after-service", query: {timestamps: Date.now()} }).then(() => {
            this.$store.dispatch(this.APIAction, param);
          });
          return;
        }
      } else {
        if (this.$route.name === "customer-after-service") {
          this.$router.push({ name: "customer-claim" ,query: {timestamps: Date.now()} }).then(() => {
            this.$store.dispatch(this.APIAction, param);
          });
          return;
        }
      }
      this.$store.dispatch(this.APIAction, param);
    }
  },
  created() {
    this.$root.$on('updateCompnent', () => {
      this.updateTableData(this.projectId)
    })
  }
};
</script>

<style lang="scss" scoped>
.customer-box {
  background: #fffff0;
  border: 1px solid #cfd180;
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;

  .form {
    &-header {
      color: #393a00;
      font-size: 16px;
    }

    &-text {
      background: #ffffff;
      //border: 2px solid #4F55A7;
      box-sizing: border-box;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
    }
  }

  &-form {
    &-input {
      ::v-deep {
        fieldset {
          border: 3px solid #4f55a7;
          box-sizing: border-box;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          background: white !important;
        }
      }
    }
  }

  &-detail {
    &-headline {
      color: #757900;
      font-size: 14px;
    }

    &-content {
      color: #333333;
      font-size: 14px;
      word-break: break-all;
    }
  }
}

.customer {
  &-name {
    &-top {
      font-weight: 700;
      font-size: 16px;
      color: #333333;
    }

    &-bottom {
      font-weight: 500;
      font-size: 18px;
      color: #333333;
    }
  }
}

.cm-add-btn {
  border-radius: 8px !important;
  color: #4f55a7;
  font-weight: 700;
  border: 1px dashed #4f55a7;
}
</style>
